<template>
  <RentStationAreasIndex
    :fst-id="`rent-station-owner-stations`"
    :pageTitle="
      $t('components.orgUserDetailsManagement.diveIntoTab.rentStation.headline')
    "
    :qso="{ append: '', prepend: '&' }"
    :hidePlusButton="true"
    :endpoint="getEndpoint"
  />
</template>

<script>
import RentStationAreasIndex from '@/views/geofence/rent-station-area/index.vue'

import { RentStationAreaConfig } from '@/config/RentStationAreaConfig'
export default {
  name: 'ViewOrganizationUserReports',

  components: {
    RentStationAreasIndex,
  },
  data() {
    return {}
  },
  computed: {
    getId() {
      return this.$route.params.id
    },

    getEndpoint() {
      return `${RentStationAreaConfig.api.index}?owner=${this.getId}`
    },
  },
}
</script>
